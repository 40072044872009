import React, { useState, FormEvent, useEffect } from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import * as Sentry from '@sentry/nextjs';
import { NextPage } from 'next';
import * as Yup from 'yup';
import { Subtract } from 'utility-types';
import PrettyError from './errors/PrettyError';
import FormValidationError from './errors/FormValidationError';
import formRequest from './formRequest';
import NotificationError from './errors/NotificationError';
import RecaptchaError from './errors/RecaptchaError';
import { ServerData } from './axios';
import { useTranslation } from 'react-i18next';

export type FormHandlerInsideResponseMiddleware<
    T extends ServerData = ServerData,
> = (response: T) => void;

export type FormEventHandler = (e?: FormEvent<HTMLFormElement>) => void;
export type FormHandlerInside<
    T extends FormikValues = {},
    P = FormikHelpers<T>,
> = (context: {
    values: T;
    actions: P;
    responseMiddleWare?: FormHandlerInsideResponseMiddleware;
}) => Promise<void>;

export { Yup, formRequest };

export type withFormContentProps = {
    isValid?: boolean;
    submitting: boolean;
    errorMessage: string | null;
    onSubmitForm: FormEventHandler;
};
export type withFormProps<T = FormikValues> = withFormPropsInit<T> & {
    isValid: boolean;
    isDirty:boolean;
    submitting: boolean;
    errorMessage: string | null;
    handleForm: (
        handle: FormEvent<HTMLFormElement> | FormHandlerInside<T>,
    ) => FormEventHandler;
    setInitValues: React.Dispatch<React.SetStateAction<T>>;
    resetForm: () => void;
};

export type withFormPropsInit<T = FormikValues> = {
    initialValuesProps?: T;
};

type withFormCtx<T = FormikValues> = {
    initialValues: T;
    validationSchema?: (props: unknown) => unknown;
    enableReinitialize?: boolean;
};

export type FormPage<P = {}, T = FormikValues> = NextPage<P & withFormProps<T>>;

const isEvent = <T,>(candidate: T) =>
    !!(
        candidate &&
        typeof candidate === 'object' &&
        'stopPropagation' in candidate &&
        'preventDefault' in candidate
    );

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const withForm = <T extends FormikValues = FormikValues>({
    initialValues,
    validationSchema,
    enableReinitialize = false,
}: withFormCtx<T>) => {
    const Extend = <P extends withFormProps<T>>(
        WrappedComponent: React.ComponentType<P>,
    ) => {
        const Wrapper: React.FC<
            Subtract<P, withFormProps<T>> & withFormPropsInit<T>
        > = ({ initialValuesProps, ...props }) => {
            const validationSchemaValues =
                typeof validationSchema === 'function'
                    ? validationSchema(props)
                    : undefined;
            const [errorMessage, setErrorMessage] = useState<string | null>(
                null,
            );
            const [initValues, setInitValues] = useState<T>({
                ...initialValues,
                ...initialValuesProps,
            });
            useEffect(() => {
                if (initialValuesProps) {
                    setInitValues({
                        ...initialValues,
                        ...initialValuesProps,
                    });
                }
            }, [initialValuesProps]);
            const { t } = useTranslation();
            let submitCallback: FormHandlerInside<T> = () => Promise.resolve();
            const onSubmitCallback = async (
                values: T,
                actions: FormikHelpers<T>,
            ) => {
                setErrorMessage(null);
                actions.setSubmitting(true);
                try {
                    await submitCallback({ values, actions });
                } catch (error) {
                    actions.setSubmitting(false);
                    // throw error;
                    if (error instanceof FormValidationError) {
                        setErrorMessage(
                            t('global.error', {
                                defaultValue:
                                    'Vienas ar keli laukeliai klaidingi. Prašome sutvarkyti ir mėginkite dar karta.',
                                ns: 'validation',
                            }),
                        );
                    } else if (error instanceof RecaptchaError) {
                        setErrorMessage(
                            t('global.recaptchaError', {
                                defaultValue:
                                    'Nepavyko patikrinti jog nesate robotas.',
                                ns: 'validation',
                            }),
                        );
                    } else if (error instanceof NotificationError) {
                        setErrorMessage(error.message);
                    } else if (error instanceof PrettyError) {
                        if (error.prev instanceof FormValidationError) {
                            setErrorMessage(
                                t('global.error', {
                                    defaultValue:
                                        'Vienas ar keli laukeliai klaidingi. Prašome sutvarkyti ir mėginkite dar karta.',
                                    ns: 'validation',
                                }),
                            );
                        } else if (error.prev instanceof RecaptchaError) {
                            setErrorMessage(
                                t('global.recaptchaError', {
                                    defaultValue:
                                        'Nepavyko patikrinti jog nesate robotas.',
                                    ns: 'validation',
                                }),
                            );
                        } else {
                            setErrorMessage(error.message);
                            if (error.prev) {
                                Sentry.setExtra('error', error.prev);
                                Sentry.captureException(error.prev);
                            }
                        }
                    } else {
                        if (error instanceof Error) {
                            setErrorMessage(error.message || 'Unkown error');
                        } else {
                            setErrorMessage('Unkown error');
                        }
                        Sentry.setExtra('error', error);
                        Sentry.captureException(error);
                    }
                }
                return Promise.resolve();
            };
            return (
                <Formik
                    initialValues={initValues}
                    enableReinitialize={enableReinitialize}
                    validationSchema={validationSchemaValues}
                    onSubmit={onSubmitCallback}
                >
                    {({ handleSubmit, isSubmitting, isValid, resetForm, dirty }) => (
                        <WrappedComponent
                            {...(props as P)}
                            submitting={isSubmitting}
                            isValid={isValid}
                            isDirty={dirty}
                            resetForm={() => {
                                resetForm(initValues);
                            }}
                            handleForm={(e) => {
                                if (!e) {
                                    return handleSubmit;
                                }
                                if (isEvent(e)) {
                                    handleSubmit(
                                        e as FormEvent<HTMLFormElement>,
                                    );
                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                    return () => {};
                                }
                                submitCallback = e as FormHandlerInside<T>;
                                return (event) => {
                                    handleSubmit(event);
                                };
                            }}
                            errorMessage={errorMessage}
                            setInitValues={setInitValues}
                        />
                    )}
                </Formik>
            );
        };
        Wrapper.displayName = `withForm(${
            WrappedComponent.displayName || WrappedComponent.name || 'Component'
        })`;
        return Wrapper;
    };
    return <P extends withFormProps<T>>(
        WrappedComponent: React.ComponentType<P>,
    ) => Extend(WrappedComponent);
};

export default withForm;
