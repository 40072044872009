import { setCookie, removeCookie } from '@utils/cookie';
import formRequest from '@utils/formRequest';
import { AppThunk, createPayloadAction } from '@store/types';
import { createAxios, ServerData } from '@root/utils/axios';
import { CaptchaContextState } from '@root/components/CaptchaProvider';
import {
    RegistrationFormValues,
    RegistrationExtraValues,
    RegistrationResponse,
    LoginFormValues,
    AUTH_TOKEN_COOCKIE,
    AuthUserUpdate,
    AuthUser,
    AuthSetUserAction,
    AuthActionTypes,
    AuthSetTokenAction,
    LoginResponse,
    SocialLoginResponse,
    PasswordResetFormValues,
    PasswordResetResponse,
    SocialLoginValidateResponse,
    SocialRegistrationResponse,
    OAuthSetTokenAction,
    SocialLoginTwoAuthFormValues,
    SocialLoginTwoAuthResponse,
} from './types';
import getConfig from 'next/config';
import { listReset } from '../services';

export const AuthSetTokenCoockie = (token: string) =>
    setCookie(AUTH_TOKEN_COOCKIE, token);

export const AuthSetToken = (token?: string): AuthSetTokenAction =>
    createPayloadAction(AuthActionTypes.AUTH_SET_TOKEN, token);

export const AuthSetUser = (user?: AuthUser): AuthSetUserAction =>
    createPayloadAction(AuthActionTypes.AUTH_SET_USER, user);

export const AuthOAuthToken = (token?: string): OAuthSetTokenAction =>
    createPayloadAction(AuthActionTypes.OAUTH_SET_TOKEN, token);

export const userAuthLogin =
    (
        values: LoginFormValues,
        captcha?: CaptchaContextState,
    ): AppThunk<Promise<ServerData<LoginResponse>>> =>
    (dispatch, getState) => {
        const { publicRuntimeConfig } = getConfig() || {
            publicRuntimeConfig: {},
        };
        const translate = getState().i18n.currentLanguage;
        return formRequest<LoginResponse>({
            url: `${publicRuntimeConfig.API_URL}/login`,
            values: { ...values, translate },
            captcha,
        }).then((response) => {
            if (response.success && response.token) {
                AuthSetTokenCoockie(response.token);
                dispatch(AuthSetToken(response.token));
                dispatch(AuthSetUser(response.user));
            }
            // TODO: error handling.
            return response;
        });
    };

export const userAuthRegister =
    (
        values: RegistrationFormValues & RegistrationExtraValues,
        captcha?: CaptchaContextState,
    ): AppThunk<Promise<ServerData<RegistrationResponse>>> =>
    (dispatch, getState) => {
        const { publicRuntimeConfig } = getConfig() || {
            publicRuntimeConfig: {},
        };
        const translate = getState().i18n.currentLanguage;
        const ref = getState().coockie.referral;
        return formRequest<RegistrationResponse>({
            url: `${publicRuntimeConfig.API_URL}/registration`,
            values: {
                ...values,
                ref: ref || undefined,
                translate,
            },
            captcha,
        }).then((response) => {
            if (response.success && response.token) {
                AuthSetTokenCoockie(response.token);
                dispatch(AuthSetToken(response.token));
                dispatch(AuthSetUser(response.user));
            }
            // TODO: error handling.
            return response;
        });
    };

export const userAuthSocialLoginRequest =
    (
        name: string,
        redirectUrl: string,
    ): AppThunk<Promise<SocialLoginResponse>> =>
    (_dispatch, getState) => {
        const token = getState().auth.token;
        const currentLanguage = getState().i18n.currentLanguage;
        const axios = createAxios(token, currentLanguage);
        return axios
            .post<SocialLoginResponse>(`/oauth/request/${name}`, {
                redirectUrl,
            })
            .then(({ data }) => {
                return data;
            });
    };

export const userAuthSocialRegistrationRequest =
    (
        name: string,
        redirectUrl: string,
        country: string,
        newsletter: string,
    ): AppThunk<Promise<SocialRegistrationResponse>> =>
    (_dispatch, getState) => {
        const token = getState().auth.token;
        const currentLanguage = getState().i18n.currentLanguage;
        const axios = createAxios(token, currentLanguage);
        const ref = getState().coockie.referral;
        return axios
            .post<SocialLoginResponse>(`/oauth/request/${name}`, {
                redirectUrl,
                data: {
                    country,
                    newsletter,
                    ref: ref || undefined,
                },
            })
            .then(({ data }) => {
                return data;
            });
    };

export const userAuthSocialLoginValidate =
    (
        name: string,
        code: string,
    ): AppThunk<Promise<SocialLoginValidateResponse>> =>
    (dispatch, getState) => {
        const token = getState().auth.token;
        const currentLanguage = getState().i18n.currentLanguage;
        const axios = createAxios(token, currentLanguage);
        return axios
            .post<SocialLoginValidateResponse>(`/oauth/login/${name}`, {
                code,
                translate: currentLanguage,
            })
            .then(({ data }) => {
                if (data.success && data.token && data.user) {
                    AuthSetTokenCoockie(data.token);
                    dispatch(AuthSetToken(data.token));
                    dispatch(AuthSetUser(data.user));
                    dispatch(listReset());
                } else if (data.success && data.token && data.require_twoauth) {
                    dispatch(AuthOAuthToken(data.token));
                } else {
                    // console.log(data);
                }
                // TODO: error handling.
                return data;
            });
    };

export const userAuthSocialRegistrationValidate =
    (
        name: string,
        code: string,
        data: string,
    ): AppThunk<Promise<SocialLoginValidateResponse>> =>
    (dispatch, getState) => {
        const token = getState().auth.token;
        const currentLanguage = getState().i18n.currentLanguage;
        const axios = createAxios(token, currentLanguage);
        return axios
            .post<SocialLoginValidateResponse>(`/oauth/register/${name}`, {
                code,
                translate: currentLanguage,
                data
            })
            .then(({ data }) => {
                if (data.success && data.token) {
                    AuthSetTokenCoockie(data.token);
                    dispatch(AuthSetToken(data.token));
                    dispatch(AuthSetUser(data.user));
                    dispatch(listReset());
                }
                // TODO: error handling.
                return data;
            });
    };

export const userAuthSocialTwoAuth =
    (
        values: SocialLoginTwoAuthFormValues,
        captcha?: CaptchaContextState,
    ): AppThunk<Promise<SocialLoginValidateResponse>> =>
    (dispatch, getState) => {
        const { publicRuntimeConfig } = getConfig() || {
            publicRuntimeConfig: {},
        };
        const translate = getState().i18n.currentLanguage;
        const token = getState().auth.oauthToken;
        const axios = createAxios(token, translate);
        return formRequest<SocialLoginTwoAuthResponse>({
            url: `${publicRuntimeConfig.API_URL}/oauth/two-auth`,
            values: { ...values, translate },
            captcha,
            axiosInstance: axios,
        }).then((data) => {
            if (data.success && data.token && data.user) {
                AuthSetTokenCoockie(data.token);
                dispatch(AuthSetToken(data.token));
                dispatch(AuthSetUser(data.user));
                dispatch(AuthOAuthToken(undefined));
            } else {
                // console.log(data);
            }
            // TODO: error handling.
            return data;
        });
    };

export const userLogout = (): AppThunk => (dispatch) => {
    removeCookie(AUTH_TOKEN_COOCKIE);
    dispatch(AuthSetToken());
    dispatch(AuthSetUser());
};

export const userAuthPasswordReset =
    (
        values: PasswordResetFormValues,
        captcha?: CaptchaContextState,
    ): AppThunk<Promise<ServerData<PasswordResetResponse>>> =>
    (dispatch, getState) => {
        const { publicRuntimeConfig } = getConfig() || {
            publicRuntimeConfig: {},
        };
        const translate = getState().i18n.currentLanguage;
        return formRequest<PasswordResetResponse>({
            url: `${publicRuntimeConfig.API_URL}/forgot-password`,
            values: {
                ...values,
                translate,
            },
            captcha,
        }).then((response) => {
            if (response.success && response.token) {
                AuthSetTokenCoockie(response.token);
                dispatch(AuthSetToken(response.token));
                dispatch(AuthSetUser(response.user));
            }
            // TODO: error handling.
            return response;
        });
    };

export const updateAuthUser =
    (data: AuthUserUpdate): AppThunk =>
    async (dispatch, getState) => {
        const { user } = getState().auth;
        if (user)
            dispatch(
                AuthSetUser({
                    ...user,
                    ...data,
                }),
            );
    };
