import { AxiosError } from 'axios';
import BaseError from './BaseError';

export type FormValidationResponse = {
    success: boolean;
    validate: string;
};

export default class FormValidationError extends BaseError {
    private response: AxiosError | null = null;

    constructor(response: AxiosError) {
        super();
        this.name = 'FormValidationError';
        this.response = response;
    }

    getResponse(): AxiosError | null {
        return this.response;
    }

    toString(): string {
        return `${this.name}: ${this.message}`;
    }
}
