import { AxiosError, AxiosResponse } from 'axios';
import { ServerData } from '../axios';
import BaseError from './BaseError';

export default class ResponseError extends BaseError {
        constructor(private error: AxiosError) {
        super(error ? error.message : 'Unknown response error.');
        this.name = 'ResponseError';
    }

    getError(): AxiosError {
        return this.error;
    }

    getResponse<T extends ServerData = ServerData>(): AxiosResponse<T> {
        return this.error!.response as AxiosResponse<T>;
    }
}
