/* eslint-disable @typescript-eslint/no-explicit-any */
import { HYDRATE } from 'next-redux-wrapper';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { FormAction, FormStateMap } from 'redux-form';
import { GetServerSidePropsContext } from 'next';
import { Store, Action as ReduxAction } from 'redux';
import { AuthState, AuthActionsType } from './auth';
import { ServicesState, ServicesActionsType } from './services';
import { CoockieActionsType, CoockieState } from './coockie';
import { I18nActionsTypes, I18nState } from './i18n';

export type Action<Type, Meta = void> = ReduxAction<Type> & { meta?: Meta };
export type PayloadAction<Type, Payload = undefined, Meta = void> = Action<
    Type,
    Meta
> & {
    readonly payload: Payload;
};
export type RequestAction<Type, Meta = void> = Action<Type, Meta>;

export const createAction = <Type extends string, Meta>(
    type: Type,
    meta?: Meta,
): Action<Type, Meta> => ({ type, meta });

export const createPayloadAction = <Type extends string, Payload, Meta>(
    type: Type,
    payload: Payload,
    meta?: Meta,
): PayloadAction<Type, Payload, Meta> => ({
    ...createAction(type, meta),
    payload,
});

export const createRequestAction = <Type extends string, Meta>(
    type: Type,
    meta?: Meta,
): RequestAction<Type, Meta> => ({
    ...createAction(type, meta),
});

export type HidrateAction = PayloadAction<typeof HYDRATE, RootState>;

export type RootAction =
    | FormAction
    | AuthActionsType
    | ServicesActionsType
    | CoockieActionsType
    | I18nActionsTypes;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    RootAction
>;

export type AppDispatch = ThunkDispatch<RootState, unknown, RootAction>;

export type RootState = {
    form: FormStateMap;
    coockie: CoockieState;
    auth: AuthState;
    i18n: I18nState;
    services: ServicesState;
};

export type StoreType = Store<RootState, RootAction>;

export interface StoreAction<T> {
    payload: T;
    type: string;
}
export type GetServerSideStoreProps =  GetServerSidePropsContext & {
    store: StoreType;
    locale?: string;
    translate?: (ns: string[] | string) => Promise<void>;
};
