import {
    AppThunk,
    createPayloadAction,
    createRequestAction,
} from '@store/types';
import { createAxios } from '@root/utils/axios';
import TsPromise from '@root/utils/exPromise';
import {
    ServicesActionTypes,
    ServiceData,
    ServicesListResponse,
    ServiceDataResponse,
    ServiceUpdateData,
    ListReset,
    ListRequest,
    ListFailed,
    ListLoaded,
    SingleRequest,
    SingleFailed,
    SingleLoaded,
    SingleUpdate,
    ServiceResource,
    SingleUpdateResources,
    SingleUpdateStateProccess,
    ServiceProccess,
} from './types';

export const listReset = (): ListReset =>
    createRequestAction(ServicesActionTypes.LIST_RESET);
export const listRequest = (): ListRequest =>
    createRequestAction(ServicesActionTypes.LIST_REQUEST);
export const listFailed = (error: string): ListFailed =>
    createPayloadAction(ServicesActionTypes.LIST_FAILED, error);
export const listLoaded = (data: ServiceData[]): ListLoaded =>
    createPayloadAction(ServicesActionTypes.LIST_LOADED, data);

export const singleRequest = (orderNo: string): SingleRequest =>
    createRequestAction(ServicesActionTypes.SINGLE_REQUEST, orderNo);
export const singleFailed = (orderNo: string, error: string): SingleFailed =>
    createPayloadAction(ServicesActionTypes.SINGLE_FAILED, error, orderNo);
export const singleLoaded = (
    orderNo: string,
    data: ServiceData,
): SingleLoaded =>
    createPayloadAction(ServicesActionTypes.SINGLE_LOADED, data, orderNo);
export const singleUpdate = (
    orderNo: string,
    data: ServiceUpdateData,
): SingleUpdate =>
    createPayloadAction(ServicesActionTypes.SINGLE_UPDATE, data, orderNo);
export const singleUpdateStateProccess = (
    orderNo: string,
    data?: ServiceProccess,
): SingleUpdateStateProccess =>
    createPayloadAction(
        ServicesActionTypes.SINGLE_UPDATE_STATE_PROCCESS,
        data,
        orderNo,
    );
export const singleUpdateResources = (
    orderNo: string,
    data: ServiceResource[],
): SingleUpdateResources =>
    createPayloadAction(
        ServicesActionTypes.SINGLE_UPDATE_RESOURCES,
        data,
        orderNo,
    );

export const listLoad = (): AppThunk<TsPromise<void>> => (
    dispatch,
    getState,
) => {
    const { token } = getState().auth;
    const { currentLanguage } = getState().i18n;
    dispatch(listRequest());
    return (createAxios(token, currentLanguage)
        .get<ServicesListResponse>('/account/services')
        .then((response) => response.data)
        .then(({ success, services }) => {
            if (success) {
                dispatch(listLoaded(services));
                return TsPromise.resolve();
            }
            dispatch(listFailed('Failed to load services list'));
            return TsPromise.reject(new Error('Failed to load services list'));
        })
        .catch((error) => {
            dispatch(listFailed(error.toString()));
            return TsPromise.reject(error);
        }) as unknown) as TsPromise<void>;
};

export const listLoadSsr = (): AppThunk<Promise<void>> => async (dispatch) => {
    try {
        await dispatch(listLoad());
        // eslint-disable-next-line no-empty
    } catch (e) {}
    return Promise.resolve();
};

export const singleLoad = (orderNo: string): AppThunk<TsPromise<void>> => (
    dispatch,
    getState,
) => {
    const { token } = getState().auth;
    const { currentLanguage } = getState().i18n;
    dispatch(singleRequest(orderNo));
    return (createAxios(token, currentLanguage)
        .get<ServiceDataResponse>(`/account/services/${orderNo}`)
        .then((response) => response.data)
        .then(({ success, service }) => {
            if (success) {
                dispatch(singleLoaded(orderNo, service));
                return TsPromise.resolve();
            }
            return TsPromise.reject(new Error('Failed to load service data'));
        })
        .catch((error) => {
            dispatch(singleFailed(orderNo, error.toString()));
            return TsPromise.reject(error);
        }) as unknown) as TsPromise<void>;
};

export const singleLoadSsr = (
    orderNo: string,
): AppThunk<Promise<void>> => async (dispatch) => {
    try {
        await dispatch(singleLoad(orderNo));
        // eslint-disable-next-line no-empty
    } catch (e) {}
    return Promise.resolve();
};

// TODO: Error handling.
export const updateServiceData = (
    orderNo: string,
    data: ServiceUpdateData,
): AppThunk => async (dispatch) => dispatch(singleUpdate(orderNo, data));
