import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';
// import { Integrations } from '@sentry/tracing';
import { get } from 'lodash';
import { IncomingMessage } from 'http';
import { AuthUser } from '@root/store/auth';

export const init = (): void => {
    const { publicRuntimeConfig } = getConfig() || { publicRuntimeConfig: {} };
    if (publicRuntimeConfig.SENTRY_DSN) {
        /*
        Sentry.init({
            enabled: process.env.NODE_ENV === 'production',
            dsn: publicRuntimeConfig.SENTRY_DSN,
            release: publicRuntimeConfig.APP_VERSION_RELEASE,
            environment: publicRuntimeConfig.APP_STAGE,
            tracesSampleRate: 1.0,

            // TODO: update tracking.
            // integrations: [new Integrations.BrowserTracing()],

            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            // tracesSampleRate: 1.0,

            // TODO: update show report dialog.
            beforeSend(event) {
                // Check if it is an exception, and if so, show the report dialog
                // if (event.exception && typeof window !== 'undefined') {
                //     SentryReact.showReportDialog({ eventId: event.event_id });
                // }
                return event;
            },
        });
        */
        // Scope configured by default, subsequent calls to "configureScope" will add additional data
        // Sentry.configureScope((scope) => {
        // See https://www.npmjs.com/package/@sentry/node
        // scope.setTag('nodejs', process.version);
        // if  (process.env.BUILD_TIME)
        //    scope.setTag('buildTime', process.env.BUILD_TIME);
        // });
    }
};

export const configureUser = (user: AuthUser): void => {
    Sentry.configureScope((scope) => {
        if (user) {
            scope.setUser({ id: user.id.toString(), email: user.email });
        } else {
            scope.setUser(null);
        }
    });
};

/**
 * Send an error event to Sentry.
 *
 * Server-side:
 * Next.js captures SSR errors and never passes them to the Sentry
 * middleware. It does, however, render the _error.js component, so
 * we can manually fire Sentry events here.
 *
 * Client-side:
 * Unhandled client exceptions will always bubble up to the _error.js
 * component, so we can manually fire events here.
 */
export const notifySentry = (
    err?: Error,
    req?: IncomingMessage,
    statusCode?: number,
): void => {
    Sentry.configureScope((scope) => {
        if (!req) {
            scope.setTag(`ssr`, 'false');
        } else {
            scope.setTag(`ssr`, 'true');
            const method = get(req, 'method');
            if (typeof method !== 'undefined') scope.setTag('method', method);
            scope.setExtra(`url`, req.url);
            scope.setExtra(`query`, get(req, 'query'));
            scope.setExtra(`statusCode`, statusCode);
            scope.setContext(`headers`, req.headers);

            /*
            if (req.user) {
                scope.setUser({ id: req.user.id, email: req.user.email });
            }
            */
        }
    });

    Sentry.captureException(err);
};

/**
export const configureReq = (req) => {
    Sentry.configureScope((scope) => {
        scope.setTag('host', get(req, 'headers.host'));
        scope.setTag('url', get(req, 'url'));
        scope.setTag('method', get(req, 'method'));
        scope.setContext('query', get(req, 'query'));
        scope.setContext('cookies', get(req, 'cookies'));
        scope.setContext('body', get(req, 'body'));
        scope.setContext('headers', get(req, 'headers'));
    });
};
*/

export default Sentry;
