import { createStore, applyMiddleware, Middleware, Store, AnyAction } from 'redux';
import { createWrapper } from 'next-redux-wrapper';
import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
// import logger from 'redux-logger';
import reducer from './reducer';
import { RootState, RootAction, AppDispatch } from './types';

const bindMiddleware = (
    ...middleware: Middleware<
        {}, // legacy type parameter added to satisfy interface signature
        RootState,
        AppDispatch
    >[] | ThunkMiddleware<any, AnyAction, undefined>[]
) => {
    let midles: Middleware<
        {}, // legacy type parameter added to satisfy interface signature
        RootState,
        AppDispatch
    >[] = [];
    /*
    if (process.env.NODE_ENV === 'development') {
        midles.push(logger);
    }
    */
    const res = applyMiddleware<AppDispatch, RootState>(
        ...midles,
        ...middleware,
    );
    if (process.env.NODE_ENV === 'development') {
        return composeWithDevTools({ trace: true, traceLimit: 25 })(res);
    }
    return res;
};

// create a makeStore function
const makeStore = () =>
    createStore(reducer, undefined, bindMiddleware(thunkMiddleware));

const StoreWrapper = createWrapper<Store<RootState, RootAction>>(makeStore, {
    debug: false,
});

export default StoreWrapper;
