import ICU from 'i18next-icu';

import HttpApi from 'i18next-http-backend';
import BackendAdapter from 'i18next-multiload-backend-adapter';

import { UserConfig } from 'next-i18next';
import { GetServerSideStoreProps } from '@root/store/types';
import { getCookie } from '@root/utils/cookie';

import { createConfig } from 'next-i18next/dist/commonjs/config/createConfig';
import createClient from 'next-i18next/dist/commonjs/createClient';
import { GetStaticPropsContext, NextPageContext } from 'next';

export { useTranslation, appWithTranslation, Trans, i18n } from 'next-i18next';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig() || { publicRuntimeConfig: {} };

export const languages = ['lt', 'en', 'ru'];
export const otherLanguages = ['en', 'ru'];
export const defaultLanguage = 'lt';
export const loadPath = `${
    publicRuntimeConfig.API_URL || 'https://api.testweb.vpsnet.com'
}/public/i18n?lng={{lng}}&ns={{ns}}`;
export const loadPathSync = (): string =>
    `${
        publicRuntimeConfig.API_URL || 'https://api.testweb.vpsnet.com'
    }/public/i18n?lng={{lng}}&ns={{ns}}`;
export const savePath = `${
    publicRuntimeConfig.API_URL || 'https://api.testweb.vpsnet.com'
}/public/i18n?lng={{lng}}&ns={{ns}}`;

export const i18nGenerateConfig = (): UserConfig & {
    backend: any;
} => {
    const use: unknown[] = [BackendAdapter];
    use.push(ICU);
    return {
        serializeConfig: false,
        i18n: {
            defaultLocale: defaultLanguage,
            locales: languages,
        },
        react: {
            useSuspense: false,
        },
        use,
        load: 'currentOnly',
        lowerCaseLng: true,
        defaultNS: 'common',
        ns: [
            'errorPage',
            'common',
            'error',
            'validation',
            'extraPaymentsMacroPay',
            'extraPaymentsUserTopup',
            'landingFeaturesReasonsTechnology',
            'landingHelpFaq',
            'landingHome',
            'landingInformationAboutCompany',
            'landingInformationLoyalty',
            'landing',
            'landingNavigation',
            'landingServicesColocation',
            'landingServicesDs',
            'landingServices',
            'landingServicesVds',
            'landingServicesVps',
            'landingContactForm',
            'login',
            'userRegistration',
            'userLoginReset',
            'passwordResetConfirm',
            'passwordReset',
            'referrals',
            'registration',
            'resourcesValues',
            'translation',
            'uiAccountLoginHistory',
            'uiAccountNotifications',
            'uiAccountPasswordChange',
            'uiAccountProfile',
            'uiAccountServiceManagementHistory',
            'uiComponent',
            'uiCopyIcon',
            'uiHelpDuk',
            'uiNavigation',
            'uiNoty',
            'uiOrder',
            'uiPartnership',
            'uiPaymentsHistory',
            'uiPaymentsInvoices',
            'uiPaymentsSystems',
            'uiPaymentsTopup',
            'uiServicesBackups',
            'uiServicesDetail',
            'uiServicesGraphs',
            'uiServices',
            'uiServicesMain',
            'uiServicesManagementHistory',
            'uiServicesOsChange',
            'uiServicesRenew',
            'uiServicesResourceChange',
            'uiServicesSettings',
            'uiServicesTransfer',
        ],
        fallbackLng: 'lt',
        keySeparator: '.',
        supportedLngs: languages,
        // ignoreRoutes: ['/_next', '/static', '/public'],
        // localePath: typeof window === "undefined" ? "public/assets/locales" : "assets/locales",
        // localePath: `${publicRuntimeConfig.API_URL}/public/i18n`,
        // localeStructure: '?{{lng}}/{{ns}}',
        // localeExtension: '',
        debug: process.env.NODE_ENV === 'development',
        strictMode: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false, // react already safes from xss
            prefix: '%',
            suffix: '%',
        },
        detection: {
            lookupCookie: 'next-i18next',
            order: ['cookie', 'header', 'querystring'],
            caches: ['cookie'],
        },
        // saveMissing: true,
        // saveMissingTo: 'all',

        backend: {
            backend: HttpApi,
            backendOption: {
                loadPath,
                addPath: savePath,
                allowMultiLoading: true,
            },
        },
    };
};

const regexFromUrl = (url: string) => {
    const regex = /^\/(\w+)\/.*$/gm;
    let m: RegExpExecArray | null = null;
    // eslint-disable-next-line no-cond-assign
    while ((m = regex.exec(url)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
            regex.lastIndex += 1;
        }

        // The result can be accessed through the `m`-variable.
        // eslint-disable-next-line no-restricted-syntax
        for (let match of m) {
            if (languages.indexOf(match) > -1) {
                return match;
            }
        }
    }
    return undefined;
};

const pathFromContext = (ctx: any) => (ctx.req as any)?._parsedUrl?.path;

export const localeFromContext = (
    context: GetServerSideStoreProps | GetStaticPropsContext | NextPageContext,
): string => {
    if ('req' in context) {
        const path = pathFromContext(context);
        if (!path || (path && path !== '/')) {
            if (context.locale) return context.locale;
            if ((context.req as any).nextRoute?.locale) {
                return (context.req as any).nextRoute.locale;
            }
        }
        if ((context.req as any).cookies) {
            const coockieLanguage = getCookie(
                'next-i18next',
                context.req as any,
            );
            if (typeof coockieLanguage !== 'undefined') {
                return coockieLanguage;
            }
        }
        if (typeof context.req?.url !== 'undefined') {
            const regexLanguage = regexFromUrl(context.req.url);
            if (typeof regexLanguage !== 'undefined') {
                return regexLanguage;
            }
        }
    }
    return 'en';
};

export const reloadI18n = async (
    initialLocale: string,
    userConfig: UserConfig,
): Promise<void> => {
    const config = createConfig({
        ...userConfig,
        lng: initialLocale,
    });
    const { i18n, initPromise } = createClient({
        ...config,
        lng: initialLocale,
    });

    await initPromise;
    await i18n.reloadResources();
};
