import { HYDRATE } from 'next-redux-wrapper';
import { Reducer } from 'redux';
import _ from 'lodash';
import {
    ServicesState,
    ServicesActionTypes,
    ServicesActionsType,
    ServiceData,
    ServiceState,
} from './types';

const initialState: ServicesState = {
    data: {},
    error: null,
    isRequesting: false,
};

const reducer: Reducer<ServicesState, ServicesActionsType> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case HYDRATE: {
            if (
                !action.payload.services.isRequesting &&
                Object.keys(action.payload.services.data).length > 0
            ) {
                return {
                    ...state,
                    ...action.payload.services,
                };
            }
            return {
                ...state,
            };
        }
        case ServicesActionTypes.LIST_RESET: {
            return {
                ...state,
                data: {},
                error: null,
                isRequesting: false,
            };
        }
        case ServicesActionTypes.LIST_REQUEST: {
            return {
                ...state,
                data: {},
                error: null,
                isRequesting: true,
            };
        }
        case ServicesActionTypes.LIST_FAILED: {
            return {
                ...state,
                data: {},
                error: { message: action.payload },
                isRequesting: false,
            };
        }
        case ServicesActionTypes.LIST_LOADED: {
            let service: ServiceData;
            return {
                ...state,
                data: Object.keys(action.payload).reduce((result, key) => {
                    service = { ...action.payload[key] };
                    // eslint-disable-next-line no-param-reassign
                    result[service.orderNo] = {
                        ...service,
                        request: {
                            error: null,
                            isRequesting: false,
                        },
                    };
                    return result;
                }, {}),
                error: null,
                isRequesting: false,
            };
        }
        case ServicesActionTypes.SINGLE_REQUEST: {
            const orderNo = action.meta as string;
            if (_.has(state.data, orderNo)) {
                const { [orderNo]: serviceObj, ...newServices } = state.data;
                return {
                    ...state,
                    data: {
                        ...newServices,
                        [orderNo]: {
                            ...serviceObj,
                            request: {
                                isRequesting: true,
                                error: null,
                            },
                        },
                    },
                };
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    [orderNo]: {
                        title: '',
                        plan: '',
                        type: '',
                        state: ServiceState.RUNING,
                        expireDate: '',
                        isCarantine: false,
                        orderNo,
                        resources: [],
                        request: {
                            isRequesting: true,
                            error: null,
                        },
                    },
                },
            };
        }
        case ServicesActionTypes.SINGLE_FAILED: {
            const orderNo = action.meta as string;
            if (!_.has(state.data, orderNo)) {
                return state;
            }
            const { [orderNo]: serviceObj, ...newServices } = state.data;
            return {
                ...state,
                data: {
                    ...newServices,
                    [orderNo]: {
                        ...serviceObj,
                        request: {
                            isRequesting: false,
                            error: { message: action.payload },
                        },
                    },
                },
            };
        }
        case ServicesActionTypes.SINGLE_LOADED: {
            const orderNo = action.meta as string;
            if (!_.has(state.data, orderNo)) {
                return state;
            }
            const { [orderNo]: serviceObj, ...newServices } = state.data;
            return {
                ...state,
                data: {
                    ...newServices,
                    [orderNo]: {
                        ...serviceObj,
                        ...action.payload,
                        request: {
                            isRequesting: false,
                            error: null,
                        },
                    },
                },
            };
        }
        case ServicesActionTypes.SINGLE_UPDATE: {
            const orderNo = action.meta as string;
            if (!_.has(state.data, orderNo)) {
                return state;
            }
            const { [orderNo]: serviceObj, ...newServices } = state.data;
            const { payload } = action;
            if (
                typeof payload.stateProccess !== 'undefined' &&
                payload.stateProccess === null
            ) {
                payload.stateProccess = undefined;
            }
            return {
                ...state,
                data: {
                    ...newServices,
                    [orderNo]: {
                        ...serviceObj,
                        ...payload,
                        request: {
                            isRequesting: false,
                            error: null,
                        },
                    },
                },
            };
        }
        case ServicesActionTypes.SINGLE_UPDATE_RESOURCES: {
            const orderNo = action.meta as string;
            if (!_.has(state.data, orderNo)) {
                return state;
            }
            const {
                [orderNo]: { resources: serviceObjRes, ...serviceObj },
                ...newServices
            } = state.data;
            let newResources = serviceObjRes;
            let index: number;
            _.each(action.payload, (res) => {
                index = _.findIndex(newResources, { type: res.type });
                if (index >= 0) {
                    newResources.splice(index, 1, res);
                } else {
                    newResources.push(res);
                }
            });
            return {
                ...state,
                data: {
                    ...newServices,
                    [orderNo]: {
                        ...serviceObj,
                        resources: newResources,
                        request: {
                            isRequesting: false,
                            error: null,
                        },
                    },
                },
            };
        }
        case ServicesActionTypes.SINGLE_UPDATE_STATE_PROCCESS: {
            const orderNo = action.meta as string;
            if (!_.has(state.data, orderNo)) {
                return state;
            }
            const {
                [orderNo]: {
                    stateProccess: serviceObjStateProcces,
                    ...serviceObj
                },
                ...newServices
            } = state.data;
            return {
                ...state,
                data: {
                    ...newServices,
                    [orderNo]: {
                        ...serviceObj,
                        stateProccess:
                            typeof action.payload !== 'undefined'
                                ? {
                                      ...(serviceObjStateProcces || {}),
                                      ...action.payload,
                                  }
                                : undefined,
                        request: {
                            isRequesting: false,
                            error: null,
                        },
                    },
                },
            };
        }
        default:
            return state;
    }
};

export default reducer;
