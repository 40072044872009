/* eslint-disable import/prefer-default-export */
import { AppThunk, createPayloadAction } from '@store/types';
import { createAxios } from '@root/utils/axios';
import { I18nActionTypes, I18nSetLanguageAction } from './types';

const I18nSetLanguage = (value: string): I18nSetLanguageAction =>
    createPayloadAction(I18nActionTypes.SET_LANGUAGE, value);

export const setLanguage =
    (language: string): AppThunk =>
    (dispatch, getState) => {
        const { token } = getState().auth;
        if (token) {
            createAxios(token)
                .post('/account/translate', {
                    translate: language,
                })
                .catch(() => {
                    // ignore
                });
        }
        dispatch(I18nSetLanguage(language));
    };
