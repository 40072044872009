import { TwoAuthFormValues } from '@root/components/TwoAuth/types';
import { ServerData } from '@root/utils/axios';
import { HidrateAction, PayloadAction } from '@store/types';

export const AUTH_TOKEN_COOCKIE = 'AUTH_TOKEN_COOCKIE';

export type AuthUser = {
    id: number;
    email: string;
    balance: number;
    vat: number;
    refIncomeCount: number;
    invoiceHint: boolean;
};

export type AuthState = {
    readonly user: AuthUser | null;
    readonly token: string | null;
    readonly oauthToken: string | null;
};

export enum AuthActionTypes {
    AUTH_SET_USER = 'AUTH_SET_USER',
    AUTH_SET_TOKEN = 'AUTH_SET_TOKEN',
    OAUTH_SET_TOKEN = 'OAUTH_SET_TOKEN',
}

export type OAuthSetTokenAction = PayloadAction<
    AuthActionTypes.OAUTH_SET_TOKEN,
    string | undefined
>;
export type AuthSetTokenAction = PayloadAction<
    AuthActionTypes.AUTH_SET_TOKEN,
    string | undefined
>;
export type AuthSetUserAction = PayloadAction<
    AuthActionTypes.AUTH_SET_USER,
    AuthUser | undefined
>;

export type AuthActionsType =
    | HidrateAction
    | OAuthSetTokenAction
    | AuthSetTokenAction
    | AuthSetUserAction;

export type RegistrationExtraValues = {
    redirectUrl?: string;
    orderUrl?: string;
};

export type RegistrationFormValues = {
    email: string;
    password: string;

    country: number;

    newsletter: boolean;

    recaptcha: string;
};

export type RegistrationResponse = {
    emailUsing?: boolean;
    refNotFund?: boolean;
    registered?: boolean;
    user?: AuthUser;
    token?: string;
    blocked?: boolean;
};

export type LoginFormValues = TwoAuthFormValues & {
    email: string;
    password: string;
};

export type LoginResponse = {
    userNotFound?: boolean;
    blocked?: boolean;
    notActive?: boolean;
    user?: AuthUser;
    token?: string;
    twoAuthCodeAppRequired?: boolean;
    twoAuthCodePinRequired?: boolean;
    invalidTwoAuthCode?: boolean;
};

export type PasswordResetFormValues = TwoAuthFormValues & {
    email: string;
    recaptcha: string;
    password?: string;
};

export type PasswordResetResponse = {
    userNotFound?: boolean;
    blocked?: boolean;
    notActive?: boolean;
    user?: AuthUser;
    token?: string;
    twoAuthCodeAppRequired?: boolean;
    twoAuthCodePinRequired?: boolean;
    invalidTwoAuthCode?: boolean;
    invalidPassword?: boolean;
};

export type AuthUserUpdate = {
    id?: number;
    login?: string;
    balance?: number;
    vat?: number;
};

export type SocialLoginResponse = ServerData<{
    url?: string;
    methodNotFound?: boolean;
}>;

export type SocialLoginValidateResponse = ServerData<{
    emailUsing?: boolean;
    userNotFound?: boolean;
    providerNotFound?: boolean;
    invalidToken?: boolean;
    require_twoauth?: boolean;
    user?: AuthUser;
    token?: string;
    blocked?: boolean;
}>;

export type SocialRegistrationResponse = ServerData<{
    url?: string;
    methodNotFound?: boolean;
}>;

export type SocialLoginTwoAuthFormValues = TwoAuthFormValues & {};
export type SocialLoginTwoAuthResponse = ServerData<{
    invalidTwoAuthCode?: boolean;
    user?: AuthUser;
    token?: string;
}>;
