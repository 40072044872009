import { HYDRATE } from 'next-redux-wrapper';
import { Reducer } from 'redux';
import { configureUser } from '@utils/sentry';
import {
    AuthState,
    AuthActionsType,
    AuthActionTypes,
    AUTH_TOKEN_COOCKIE,
} from './types';
import { setCookie } from '@root/utils/cookie';

const initialState: AuthState = {
    token: null,
    oauthToken: null,
    user: null,
};

const authReducer: Reducer<AuthState, AuthActionsType> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case HYDRATE: {
            if (action.payload.auth.token) {
                setCookie(AUTH_TOKEN_COOCKIE, action.payload.auth.token);
            }
            return {
                ...state,
                ...action.payload.auth,
            };
        }
        case AuthActionTypes.AUTH_SET_TOKEN: {
            return {
                ...state,
                token: action.payload || null,
            };
        }
        case AuthActionTypes.OAUTH_SET_TOKEN: {
            return {
                ...state,
                oauthToken: action.payload || null,
            };
        }
        case AuthActionTypes.AUTH_SET_USER:
            if (action.payload) {
                configureUser(action.payload);
            }
            return {
                ...state,
                user: action.payload || null,
            };
        default:
            return state;
    }
};

export default authReducer;
