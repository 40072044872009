import { AxiosError } from 'axios';
import BaseError from './BaseError';

export default class NetworkError extends BaseError {
    private response: AxiosError | null = null;

    constructor(response: AxiosError) {
        super();
        this.name = 'NetworkError';
        this.response = response;
    }

    getResponse(): AxiosError | null {
        return this.response;
    }
}
