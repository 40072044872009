import BaseError from './BaseError';

export default class RecaptchaError extends BaseError {
    private error: Error | null = null;

    constructor(error: Error) {
        super();
        this.name = 'RecaptchaError';
        this.error = error;
    }

    getError(): Error | null {
        return this.error;
    }

    toString(): string {
        return `${this.name}: ${this.error?.message}`;
    }
}
