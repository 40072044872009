import { CaptchaContextState } from '@root/components/CaptchaProvider';
import axios, { ServerData, ValidationServerData } from './axios';
import FormValidationError from './errors/FormValidationError';
import RecaptchaError from './errors/RecaptchaError';
import ResponseError from './errors/ResponseError';
import { isValidationData } from './useFormRequest';

type AxiosType = typeof axios;

// const sleep = (ms: number) => new Promise((resolve: any) => setTimeout(resolve, ms));

export default async function formRequest<T = {}>({
    url,
    values,
    axiosInstance,
    captcha,
}: {
    url: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
    axiosInstance?: AxiosType;
    captcha?: CaptchaContextState;
}) {
    if (!axiosInstance) {
        // eslint-disable-next-line no-param-reassign
        axiosInstance = axios;
    }
    if (captcha) {
        await captcha.reset();
        try {
            const recaptcha = await captcha.exec();
            /*
            if (typeof recaptcha !== 'string') {
                throw new Error('Fialed to handle captcha');
            }
            if (recaptcha.length === 0) {
                throw new Error('Fialed to handle captcha');
            }
            */
            // eslint-disable-next-line no-param-reassign
            values.recaptcha = recaptcha;
        } catch (e) {
            if (e instanceof Error) {
                throw new RecaptchaError(e);
            }
            throw new RecaptchaError(new Error('Uknow error'));
        }
    }
    // await sleep(5000);
    return axiosInstance
        .request<ServerData<T>>({
            url: `${url}`,
            data: values,
            method: 'POST',
        })
        .then(({ data }) => data)
        .catch((reason) => {
            const error = reason as Error;
            if (error instanceof ResponseError) {
                const response = error.getResponse<
                    ValidationServerData | ServerData
                >();
                if (
                    response.status === 422 &&
                    response.data.success === false &&
                    isValidationData(response.data) &&
                    response.data.validate &&
                    response.data.validate === 'error'
                ) {
                    throw new FormValidationError(error.getError());
                }
            }
            throw reason;
        });
}
