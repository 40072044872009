import cookie from 'js-cookie';
import { NextApiRequest, NextPageContext } from 'next';

export const setCookie = (
    key: string,
    value: string | Record<string, unknown>,
): void => {
    if (process.browser) {
        cookie.set(key, value, {
            // expires: 1,
            path: '/',
            domain:
                process.env.NODE_ENV !== 'production'
                    ? undefined
                    : '.vpsnet.com',
            secure: true,
        });
    }
};

export const removeCookie = (key: string): void => {
    if (process.browser) {
        cookie.remove(key, {
            path: '/',
            domain:
                process.env.NODE_ENV !== 'production'
                    ? undefined
                    : '.vpsnet.com',
            secure: true,
        });
    }
};

const getCookieFromBrowser = (key: string): string | undefined => {
    return cookie.get(key);
};

const getCookieFromServer = (
    key: string,
    req: NonNullable<NextPageContext['req']> | NextApiRequest,
): string | undefined => {
    if (!req.headers.cookie) {
        return undefined;
    }
    const rawCookie = req.headers.cookie
        .split(';')
        .find((c) => c.trim().startsWith(`${key}=`));
    if (!rawCookie) {
        return undefined;
    }
    return rawCookie.split('=')[1];
};

export const getCookie = (
    key: string,
    req?: NonNullable<NextPageContext['req']> | NextApiRequest,
): string | undefined => {
    if (!req) {
        return undefined;
    }
    return process.browser
        ? getCookieFromBrowser(key)
        : getCookieFromServer(key, req);
};
