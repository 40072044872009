import { CaptchaContextState } from '@root/components/CaptchaProvider';
import { ServerData, useAxios, ValidationServerData } from './axios';
import FormValidationError from './errors/FormValidationError';
import NotificationError from './errors/NotificationError';
import PrettyError from './errors/PrettyError';
import ResponseError from './errors/ResponseError';
import TsPromise from './exPromise';
import { FormHandlerInside, formRequest } from './withForm';

type UseFromRequestProps<Values extends {} = {}> = {
    url: string;
    values?: Record<string, unknown> | ((values: Values) => Promise<Record<string, unknown>> | Promise<Values> | Record<string, unknown> | Values);
    captcha?: CaptchaContextState;
};

type UseFromRequestReturn<Values extends {} = {}> = {
    formHandle: FormHandlerInside<Values>;
};

export function isValidationData(
    data: ValidationServerData | ServerData,
): data is ValidationServerData {
    return (data as ValidationServerData).validate !== undefined;
}

export const useFormRequest = <
    Values extends {} = {},
    Response extends ServerData = ServerData,
>(
    { url, captcha, values: globaValues }: UseFromRequestProps<Values>,
    handle: (response: Response) => Promise<void> | void,
    fatalError?: string,
): UseFromRequestReturn<Values> => {
    const axiosInstance = useAxios();

    const formHandle: FormHandlerInside<Values> = async ({
        values,
        responseMiddleWare,
    }) => {
        try {
            let newValues: Record<string, unknown>;
            if (typeof globaValues === 'function') {
                newValues = await globaValues(values);
            }else {
                newValues = {
                    ...values,
                    ...globaValues,
                };
            }
            const response = await formRequest<Response>({
                url,
                values: newValues,
                axiosInstance,
                captcha,
            });
            if (responseMiddleWare) {
                responseMiddleWare(response);
            }
            await handle(response);
            return TsPromise.resolve();
        } catch (reason) {
            const error = reason as Error;
            if (error instanceof ResponseError) {
                const response = error.getResponse<
                    ValidationServerData | ServerData
                >();
                if (
                    response.status === 422 &&
                    response.data.success === false &&
                    isValidationData(response.data) &&
                    response.data.validate &&
                    response.data.validate === 'error'
                ) {
                    throw new FormValidationError(error.getError());
                }
            }
            if (reason instanceof NotificationError) {
                throw reason;
            }
            if (!(reason instanceof PrettyError) && fatalError) {
                throw new PrettyError(fatalError, reason as Error);
            }
            throw error;
        }
    };

    return {
        formHandle,
    };
};
