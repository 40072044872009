import { AuthSetToken, AUTH_TOKEN_COOCKIE } from '@root/store/auth';
import { getCookie } from '../utils/cookie';
import { setLanguage } from './i18n/actions';
import { hideAlert, setReferral } from './coockie/actions';
import { AppDispatch } from './types';
import { COOCKIE_HIDE_ALERT_COOCKIE, COOCKIE_REF_COOCKIE } from './coockie';
import { NextPageContext } from 'next';
import { localeFromContext } from '@root/plugins/i18n';

const StoreInitialize = async (context: NextPageContext): Promise<void> => {
    const { req, store } = context;
    const dispatch = store.dispatch as AppDispatch;
    if (getCookie(COOCKIE_HIDE_ALERT_COOCKIE, req) === 'true') {
        dispatch(hideAlert());
    }
    const refCoockie = getCookie(COOCKIE_REF_COOCKIE, req);
    if (typeof refCoockie !== 'undefined' && refCoockie !== null) {
        dispatch(setReferral(refCoockie));
    }
    // console.log(Object.keys(context.req));
    if (req) {
        const token = getCookie(AUTH_TOKEN_COOCKIE, req);
        if (token) {
            dispatch(AuthSetToken(token));
        }
        dispatch(setLanguage(localeFromContext(context)));
    } else {
        const token = getCookie(AUTH_TOKEN_COOCKIE);
        if (token) {
            dispatch(AuthSetToken(token));
        }
        dispatch(setLanguage(getCookie('next-i18next') || 'en'));
    }
    return Promise.resolve();
};

export default StoreInitialize;
