import { HidrateAction, PayloadAction, RequestAction } from '@store/types';
import { ServerData } from '@root/utils/axios';

export type serviceRequestFailedType = {
    name?: string;
    message: string;
};

export type serviceRequestType = {
    readonly error: serviceRequestFailedType | null;
    readonly isRequesting: boolean;
};

export type ServicesState = serviceRequestType & {
    readonly data: Record<string, ServiceData>;
};

export enum ServicesActionTypes {
    LIST_RESET = 'LIST_RESET',
    LIST_REQUEST = 'LIST_REQUEST',
    LIST_LOADED = 'LIST_LOADED',
    LIST_FAILED = 'LIST_FAILED',
    SINGLE_REQUEST = 'SINGLE_REQUEST',
    SINGLE_FAILED = 'SINGLE_FAILED',
    SINGLE_LOADED = 'SINGLE_LOADED',
    SINGLE_UPDATE = 'SINGLE_UPDATE',
    SINGLE_UPDATE_RESOURCES = 'SINGLE_UPDATE_RESOURCES',
    SINGLE_UPDATE_STATE_PROCCESS = 'SINGLE_UPDATE_STATE_PROCCESS',
}

export type ListReset = RequestAction<ServicesActionTypes.LIST_RESET>;
export type ListRequest = RequestAction<ServicesActionTypes.LIST_REQUEST>;
export type ListFailed = PayloadAction<ServicesActionTypes.LIST_FAILED, string>;
export type ListLoaded = PayloadAction<
    ServicesActionTypes.LIST_LOADED,
    ServiceData[]
>;

export type SingleRequest = RequestAction<
    ServicesActionTypes.SINGLE_REQUEST,
    string
>;
export type SingleFailed = PayloadAction<
    ServicesActionTypes.SINGLE_FAILED,
    string,
    string
>;
export type SingleLoaded = PayloadAction<
    ServicesActionTypes.SINGLE_LOADED,
    ServiceData,
    string
>;
export type SingleUpdate = PayloadAction<
    ServicesActionTypes.SINGLE_UPDATE,
    ServiceUpdateData,
    string
>;
export type SingleUpdateResources = PayloadAction<
    ServicesActionTypes.SINGLE_UPDATE_RESOURCES,
    ServiceResource[],
    string
>;
export type SingleUpdateStateProccess = PayloadAction<
    ServicesActionTypes.SINGLE_UPDATE_STATE_PROCCESS,
    ServiceProccess | undefined,
    string
>;

export type ServicesActionsType =
    | HidrateAction
    | ListReset
    | ListRequest
    | ListFailed
    | ListLoaded
    | SingleRequest
    | SingleFailed
    | SingleLoaded
    | SingleUpdate
    | SingleUpdateResources
    | SingleUpdateStateProccess;

export type ServicesListResponse = ServerData & {
    services: ServiceData[];
};

export type ServiceDataResponse = ServerData & {
    service: ServiceData;
};

export type ServiceHardwareResource = {
    type: 'ram' | 'cpu' | 'ssd' | 'storage' | 'gpu';
    value: string;
    translate: string;
    orginalValue: string | null;
};

export type ServiceNetworkResource = {
    type: 'bandwidth' | 'traffic';
    value: string;
    translate: string;
};

export type ServiceIpResource = {
    type: 'ip';
    value: string;
    translate: string;
};

export type ServiceOsGroup = {
    name: string;
    translate: string;
};

export type ServiceOsResource = {
    type: 'os';
    group: ServiceOsGroup | null;
    value: string | null;
    translate: string | null;
};

export type ServiceResource =
    | ServiceHardwareResource
    | ServiceNetworkResource
    | ServiceIpResource
    | ServiceOsResource;

export enum ServiceState {
    CREATING = 'creating',
    RUNING = 'running',
    STARTING = 'starting',
    STOPPED = 'stopped',
    STOPPING = 'stopping',
    RESTARTING = 'restarting',
}

export type ServiceProccess = {
    type: string;
    percentage: number;
    event?: number;
};

export type ServiceResourceUsage = ServiceResource & {
    percentage: number;
};

export type ServiceDesroyExtra = {
    days: number;
    hours: number;
};

export type ServiceExtraSettings = {
    ipv6_enabled: boolean;
    ppp_enabled: boolean;
    fuse_enabled: boolean;
    tuntap_enabled: boolean;
    nfs_enabled: boolean;
};

export type ServiceExtra = {
    destroy?: ServiceDesroyExtra;
    daysLeft: number;
    lram: number;
    ips: Array<string>;
    resourcesUsage: ServiceResourceUsage[];
    settings: ServiceExtraSettings;
};

export type ServiceConsoleData = {
    host: string;
    password: string;
    port: string;
};

export type ServiceData = {
    title: string;
    type: string;
    plan: string;
    state: ServiceState;
    stateProccess?: ServiceProccess;
    isCarantine: boolean;
    expireDate: string;
    orderNo: string;
    resources: ServiceResource[];
    extra?: ServiceExtra;
    request: serviceRequestType;
    console?: ServiceConsoleData;
    allow_plan_change?: number;
};

export type ServiceUpdateData = {
    title?: string;
    plan?: string;
    state?: ServiceState;
    stateProccess?: ServiceProccess;
    isCarantine?: boolean;
    expireDate?: string;
    orderNo?: string;
    resources?: ServiceResource[];
    extra?: ServiceExtra;
    console?: ServiceConsoleData;
};
