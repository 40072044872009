/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import TsPromise from 'ts-promise';

export type ErrorClass = new (...args: any[]) => Error;

export default TsPromise;

export const catchNot = (predicate: ErrorClass | ErrorClass[]) => (
    reason: any,
): boolean => {
    let match = false;
    if (Array.isArray(predicate)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const p of predicate) {
            if (!(reason instanceof p)) {
                match = true;
                break;
            }
        }
    } else if (predicate.prototype instanceof Error || predicate === Error) {
        match = !(reason instanceof predicate);
    }
    return !!match;
};
