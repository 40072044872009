// TODO: Check for import statement
// eslint-disable-next-line @typescript-eslint/no-var-requires
const routes = require('next-routes-with-locale')({
    locale: 'lt',
});

interface RoutesOptions {
    name: string;
    path: string;
    values: {
        [key: string]: string;
    };
    options?: any;
}

const routesOptiosn: Array<RoutesOptions> = [
    // Landing
    {
        name: 'landing_home_alias',
        path: '/Landing/Home/Index',
        values: {
            lt: '/',
            en: '/',
        },
    },
    {
        name: 'landing_home',
        path: '/Landing/Home/Index',
        values: {
            lt: '/pagrindinis',
            en: '/home',
        },
    },
    {
        name: 'landing_services_vps',
        path: '/Landing/Services/VPS/Index',
        values: {
            lt: '/paslaugos/vps',
            en: '/services/vps',
        },
    },
    {
        name: 'landing_services_vds',
        path: '/Landing/Services/VDS/Index',
        values: {
            lt: '/paslaugos/vds',
            en: '/services/vds',
        },
    },
    {
        name: 'landing_services_ds',
        path: '/Landing/Services/DS/Index',
        values: {
            lt: '/paslaugos/dedikuoti-serveriai',
            en: '/services/dedicated-servers',
        },
    },
    {
        name: 'landing_services_colocation',
        path: '/Landing/Services/Colocation/Index',
        values: {
            lt: '/paslaugos/kolokacija',
            en: '/services/server-colocation',
        },
    },
    {
        name: 'landing_services_faq',
        path: '/Landing/Help/Faq/Index',
        values: {
            lt: '/pagalba/duk',
            en: '/help/faq',
        },
    },
    {
        name: 'landing_live_help',
        path: '/Landing/Help/Faq/Index',
        values: {
            lt: '/pagalba/pagalba-gyvai',
            en: '/help/live-help',
        },
        options: {
            isLiveHelp: true,
        },
    },
    {
        name: 'landing_contacts',
        path: '/Landing/Information/AboutCompany/Index',
        values: {
            lt: '/pagalba/kontaktai',
            en: '/help/contacts',
        },
        options: {
            isContact: true,
        },
    },
    {
        name: 'landing_information_term_of_service',
        path: '/Landing/Information/Documents/Index',
        values: {
            lt: '/informacija/paslaugu-teikimo-salygos',
            en: '/information/term-of-service',
        },
        options: {
            isTos: true,
        },
    },
    {
        name: 'landing_information_privacy_policy',
        path: '/Landing/Information/Documents/Index',
        values: {
            lt: '/informacija/privatumo-politika',
            en: '/information/privacy-policy',
        },
        options: {
            isPrivacyPolicy: true,
        },
    },
    {
        name: 'landing_information_refund_policy',
        path: '/Landing/Information/Documents/Index',
        values: {
            lt: '/informacija/paslaugu-grazinimo-politika',
            en: '/information/refund-policy',
        },
        options: {
            isRefundPolicy: true,
        },
    },
    {
        name: 'landing_services_about_company',
        path: '/Landing/Information/AboutCompany/Index',
        values: {
            lt: '/informacija/apie-kompanija',
            en: '/information/about-company',
        },
    },
    {
        name: 'landing_services_loyalty',
        path: '/Landing/Information/Loyalty/Index',
        values: {
            lt: '/informacija/lojalumo-programa-ir-rekomendaciju-sistema',
            en: '/information/loyalty-and-referral-sytem',
        },
    },
    {
        name: 'landing_services_reasons_technology',
        path: '/Landing/Features/ReasonsTechnology/Index',
        values: {
            lt: '/priezastys-ir-technologija',
            en: '/reasons-and-technology',
        },
    },
    {
        name: 'landing_services_reasons_hardware',
        path: '/Landing/Features/ReasonsTechnology/Index',
        values: {
            lt: '/privalumai/technine-iranga',
            en: '/advantages/equipment',
        },
        options: {
            isHardwareSection: true,
        },
    },
    {
        name: 'landing_services_reasons_control',
        path: '/Landing/Features/ReasonsTechnology/Index',
        values: {
            lt: '/privalumai/valdymo-panele',
            en: '/advantages/control-panel',
        },
        options: {
            isControlSection: true,
        },
    },
    {
        name: 'auth_registration_confirm',
        path: '/Landing/Registration/Confirm',
        values: {
            lt: '/registracijos/patvirtinimas',
            en: '/registration/confirm',
        },
    },
    // Auth
    {
        name: 'auth_registration',
        path: '/Auth/Registration/Index',
        values: {
            lt: '/registracija',
            en: '/registration',
        },
    },
    {
        name: 'auth_login',
        path: '/Auth/Login/Index',
        values: {
            lt: '/prisijungimas',
            en: '/login',
        },
    },
    {
        name: 'auth_password_reset',
        path: '/Auth/PasswordReset/Index',
        values: {
            lt: '/slaptazodzio-priminimas',
            en: '/password-reset',
        },
    },
    // UI
    {
        name: 'ui_services_list',
        path: '/Ui/Services/Main/Index',
        values: {
            lt: '/valdymas/paslaugos',
            en: '/management/service',
        },
    },
    {
        name: 'ui_services_resources',
        path: '/Ui/Services/ResourceChange/Index',
        values: {
            lt: '/valdymas/paslauga/:id/resursu-keitimas',
            en: '/management/service/:id/resource-change',
        },
    },
    {
        name: 'ui_services_os',
        path: '/Ui/Services/OsChange/Index',
        values: {
            lt: '/valdymas/paslauga/:id/os-keitima',
            en: '/management/service/:id/os-change',
        },
    },
    {
        name: 'ui_services_settings',
        path: '/Ui/Services/Settings/Index',
        values: {
            lt: '/valdymas/paslauga/:id/nustatymai',
            en: '/management/service/:id/settings',
        },
    },
    {
        name: 'ui_services_settings_hostname',
        path: '/Ui/Services/Settings/Index',
        values: {
            lt: '/valdymas/paslauga/:id/nustatymai/hostname',
            en: '/management/service/:id/settings/hostname',
        },
    },
    {
        name: 'ui_services_settings_root_password',
        path: '/Ui/Services/Settings/Index',
        values: {
            lt: '/valdymas/paslauga/:id/nustatymai/pakeisti-root-slaptazodi',
            en: '/management/service/:id/settings/change-root-password',
        },
    },
    {
        name: 'ui_services_settings_ns',
        path: '/Ui/Services/Settings/Index',
        values: {
            lt: '/valdymas/paslauga/:id/nustatymai/vardu-serveriu-keitimas',
            en: '/management/service/:id/settings/modify-name-servers',
        },
    },
    {
        name: 'ui_services_settings_rdns',
        path: '/Ui/Services/Settings/Index',
        values: {
            lt: '/valdymas/paslauga/:id/nustatymai/rdns-koregavimas',
            en: '/management/service/:id/settings/modify-rdns',
        },
    },
    {
        name: 'ui_services_settings_server_title',
        path: '/Ui/Services/Settings/Index',
        values: {
            lt: '/valdymas/paslauga/:id/nustatymai/serverio-pavadinimo-koregavimas',
            en: '/management/service/:id/settings/modify-server-title',
        },
    },
    {
        name: 'ui_services_settings_extra',
        path: '/Ui/Services/Settings/Index',
        values: {
            lt: '/valdymas/paslauga/:id/nustatymai/papildomi-nustatymai',
            en: '/management/service/:id/settings/extra-settings',
        },
    },
    {
        name: 'ui_services_settings_iptables',
        path: '/Ui/Services/Settings/Index',
        values: {
            lt: '/valdymas/paslauga/:id/nustatymai/ip-tables',
            en: '/management/service/:id/settings/ip-tables',
        },
    },
    {
        name: 'ui_services_settings_ipv6',
        path: '/Ui/Services/Settings/Index',
        values: {
            lt: '/valdymas/paslauga/:id/nustatymai/ipv6',
            en: '/management/service/:id/settings/ipv6',
        },
    },
    {
        name: 'ui_services_graphs',
        path: '/Ui/Services/Graphs/Index',
        values: {
            lt: '/valdymas/paslauga/:id/grafikai',
            en: '/management/service/:id/graphs',
        },
    },
    {
        name: 'ui_services_backups',
        path: '/Ui/Services/Backups/Index',
        values: {
            lt: '/valdymas/paslauga/:id/backupai',
            en: '/management/service/:id/backups',
        },
    },
    {
        name: 'ui_services_backups_success',
        path: '/Ui/Services/Backups/PaymentStatus/Success',
        values: {
            lt: '/valdymas/paslauga/:id/backupai/sekmingas',
            en: '/management/service/:id/backups/success',
        },
    },
    {
        name: 'ui_services_backups_cancel',
        path: '/Ui/Services/Backups/PaymentStatus/Cancel',
        values: {
            lt: '/valdymas/paslauga/:id/backupai/atsauktas',
            en: '/management/service/:id/backups/cancel',
        },
    },
    {
        name: 'ui_services_renew',
        path: '/Ui/Services/Renew/Index',
        values: {
            lt: '/valdymas/paslauga/:id/pratesimas',
            en: '/management/service/:id/renew',
        },
    },
    {
        name: 'ui_services_renew_success',
        path: '/Ui/Services/Renew/Main/PaymentStatus/Success',
        values: {
            lt: '/valdymas/paslauga/:id/pratesimas/sekmingas',
            en: '/management/service/:id/renew/success',
        },
    },
    {
        name: 'ui_services_renew_cancel',
        path: '/Ui/Services/Renew/Main/PaymentStatus/Cancel',
        values: {
            lt: '/valdymas/paslauga/:id/pratesimas/atsauktas',
            en: '/management/service/:id/renew/cancel',
        },
    },
    // UI
    {
        name: 'ui_services_transfer',
        path: '/Ui/Services/Transfer/Request/Index',
        values: {
            lt: '/valdymas/paslauga/:id/perleidimas',
            en: '/management/service/:id/transfer',
        },
    },
    {
        name: 'ui_services_transfer_confirm',
        path: '/Ui/Services/Transfer/Confirm/Index',
        values: {
            lt: '/valdymas/paslauga/:id/perleidimas/patvirtinimas/:hash',
            en: '/management/service/:id/transfer/confirm/:hash',
        },
    },
    {
        name: 'ui_services_transfer_complete',
        path: '/Ui/Services/Transfer/Complete/Index',
        values: {
            lt: '/valdymas/paslauga/:id/perleidimas/uzbaigimas/:hash',
            en: '/management/service/:id/transfer/complete/:hash',
        },
    },
    {
        name: 'ui_services_management_history',
        path: '/Ui/Services/ManagementHistory/Index',
        values: {
            lt: '/valdymas/paslauga/:id/valdymo-istorija',
            en: '/management/service/:id/management-history',
        },
    },
    {
        name: 'ui_services_detail',
        path: '/Ui/Services/Detail/Index',
        values: {
            lt: '/valdymas/paslauga/:id',
            en: '/management/service/:id',
        },
    },
    {
        name: 'ui_order_success',
        path: '/Ui/Order/Payment/Success',
        values: {
            lt: '/valdymas/uzsakymas/sekmingas',
            en: '/management/order/success',
        },
    },
    {
        name: 'ui_order_cancel',
        path: '/Ui/Order/Payment/Cancel',
        values: {
            lt: '/valdymas/uzsakymas/atsauktas',
            en: '/management/order/cancel',
        },
    },
    {
        name: 'ui_order',
        path: '/Ui/Order/Main/Index',
        values: {
            lt: '/valdymas/uzsakymas',
            en: '/management/order',
        },
    },
    {
        name: 'ui_duk',
        path: '/Ui/Help/Faq/Index',
        values: {
            lt: '/valdymas/pagalba/duk',
            en: '/management/help/faq',
        },
    },
    {
        name: 'ui_partnership_banners',
        path: '/Ui/Partnership/Banners/Index',
        values: {
            lt: '/valdymas/partneryste/baneriai',
            en: '/management/partnership/banners',
        },
    },
    {
        name: 'ui_partnership_referrals',
        path: '/Ui/Partnership/Referrals/Index',
        values: {
            lt: '/valdymas/partneryste/referalai',
            en: '/management/partnership/referrals',
        },
    },
    {
        name: 'ui_partnership_coupons',
        path: '/Ui/Partnership/Coupons/Index',
        values: {
            en: '/management/partnership/coupons',
            lt: '/valdymas/partneryste/kuponai',
        },
    },
    {
        name: 'ui_account_profile',
        path: '/Ui/Account/Profile/Index',
        values: {
            en: '/management/account/profile',
            lt: '/valdymas/paskyra/profilis',
        },
    },
    {
        name: 'ui_account_twoauth',
        path: '/Ui/Account/TwoAuth/Index',
        values: {
            en: '/management/account/2fa',
            lt: '/valdymas/paskyra/2fa',
        },
    },
    {
        name: 'ui_account_social_accounts',
        path: '/Ui/Account/SocialAccounts/Index',
        values: {
            en: '/management/account/social-accounts',
            lt: '/valdymas/paskyra/socialines-paskyros',
        },
    },
    {
        name: 'ui_account_password_change',
        path: '/Ui/Account/PasswordChange/Index',
        values: {
            en: '/management/account/password-change',
            lt: '/valdymas/paskyra/slaptazodzio-keitimas',
        },
    },
    {
        name: 'ui_account_email_change',
        path: '/Ui/Account/EmailChange/Request/Index',
        values: {
            en: '/management/account/email-change',
            lt: '/valdymas/paskyra/el-pasto-keitimas',
        },
    },
    {
        name: 'ui_account_email_change_confirm',
        path: '/Ui/Account/EmailChange/Confirm/Index',
        values: {
            en: '/management/account/email-change/confirm/:hash',
            lt: '/valdymas/paskyra/el-pasto-keitimas/patvirtinimas/:hash',
        },
    },
    {
        name: 'ui_account_email_change_complete',
        path: '/Ui/Account/EmailChange/Complete/Index',
        values: {
            en: '/management/account/email-change/complete/:hash',
            lt: '/valdymas/paskyra/el-pasto-keitimas/uzbaigimas/:hash',
        },
    },
    {
        name: 'ui_account_notifications',
        path: '/Ui/Account/Notifications/Index',
        values: {
            en: '/management/account/notifications',
            lt: '/valdymas/paskyra/pranesimai',
        },
    },
    {
        name: 'ui_account_login_history',
        path: '/Ui/Account/LoginHistory/Index',
        values: {
            en: '/management/account/login-history',
            lt: '/valdymas/paskyra/prisijungimu-istorija',
        },
    },
    {
        name: 'ui_account_service_management_history',
        path: '/Ui/Account/ServiceManagementHistory/Index',
        values: {
            en: '/management/account/services-management-history',
            lt: '/valdymas/paskyra/paslaugu-valdymo-istorija',
        },
    },
    {
        name: 'ui_payments_topup',
        path: '/Ui/Payments/Topup/Index',
        values: {
            en: '/management/payments/add-funds',
            lt: '/valdymas/mokejimai/papildymas',
        },
    },
    {
        name: 'ui_payments_topup_success',
        path: '/Ui/Payments/Topup/Macro/Success',
        values: {
            en: '/management/payments/add-funds/success',
            lt: '/valdymas/mokejimai/papildymas/sekmingas',
        },
    },
    {
        name: 'ui_payments_topup_cancel',
        path: '/Ui/Payments/Topup/Macro/Cancel',
        values: {
            en: '/management/payments/add-funds/cancel',
            lt: '/valdymas/mokejimai/papildymas/atsauktas',
        },
    },
    {
        name: 'ui_payments_invoices',
        path: '/Ui/Payments/Invoices/Index',
        values: {
            en: '/management/payments/invoices',
            lt: '/valdymas/mokejimai/saskaitos-fakturos',
        },
    },
    {
        name: 'ui_payments_invoices_show',
        path: '/Ui/Payments/Invoices/Show',
        values: {
            en: '/management/payments/invoices/:hash',
            lt: '/valdymas/mokejimai/saskaitos-fakturos/:hash',
        },
    },
    {
        name: 'ui_payments_macro_system',
        path: '/Ui/Payments/Systems/Macro/Index',
        values: {
            en: '/management/payments/automatic-system',
            lt: '/valdymas/mokejimai/automatine-sistema',
        },
    },
    {
        name: 'ui_payments_micro_system',
        path: '/Ui/Payments/Systems/Micro/Index',
        values: {
            en: '/management/payments/sms-system',
            lt: '/valdymas/mokejimai/sms-sistema',
        },
    },
    {
        name: 'ui_payments_history',
        path: '/Ui/Payments/History/Index',
        values: {
            en: '/management/payments/history',
            lt: '/valdymas/mokejimai/istorija',
        },
    },
    {
        name: 'ui_payments_user_topup_success',
        path: '/Extra/Payments/UserTopup/Success',
        values: {
            en: '/user-add-funds/success',
            lt: '/vartotojo-balanso-papildymas/sekmingas',
        },
    },
    {
        name: 'ui_payments_user_topup_cancel',
        path: '/Extra/Payments/UserTopup/Cancel',
        values: {
            en: '/user-add-funds/cancel',
            lt: '/vartotojo-balanso-papildymas/atsauktas',
        },
    },
    {
        name: 'ui_payments_user_topup',
        path: '/Extra/Payments/UserTopup/Index',
        values: {
            en: '/user-add-funds/:hash',
            lt: '/vartotojo-balanso-papildymas/:hash',
        },
    },
    {
        name: 'ui_payments_macro',
        path: '/Extra/Payments/MacroPay/Index',
        values: { en: '/macro-pay/:hash?', lt: '/macro-apmokejimas/:hash?' },
    },
    {
        name: 'ui_payments_macro_success',
        path: '/Extra/Payments/MacroPay/Success',
        values: {
            en: '/macro-pay/success/:hash',
            lt: '/macro-apmokejimas/sekmingas/:hash',
        },
    },
    {
        name: 'ui_payments_macro_cancel',
        path: '/Extra/Payments/MacroPay/Cancel',
        values: {
            en: '/macro-pay/cancel/:hash',
            lt: '/macro-apmokejimas/atsauktas/:hash',
        },
    },
    {
        name: 'ui_payments_pre_invoice',
        path: '/Extra/Payments/PreInvoice/Index',
        values: {
            lt: '/isankstine-pvm-saskaitos-faktura/:hash',
            en: '/pre-invoice/:hash',
        },
    },
    {
        name: 'ui_payments_pre_invoice_success',
        path: '/Extra/Payments/PreInvoice/Success',
        values: {
            lt: '/isankstine-pvm-saskaitos-faktura/sekmingas/:hash',
            en: '/pre-invoice/success/:hash',
        },
    },
    {
        name: 'ui_payments_pre_invoice_cancel',
        path: '/Extra/Payments/PreInvoice/Cancel',
        values: {
            lt: '/isankstine-pvm-saskaitos-faktura/atsauktas/:hash',
            en: '/pre-invoice/cancel/:hash',
        },
    },
    {
        name: 'landing_ref_alias',
        path: '/Extra/Ref/Index',
        values: { lt: '/rekomenduoti', en: '/Referall' },
    },
    {
        name: 'landing_ref',
        path: '/Extra/Ref/Index',
        values: { lt: '/rekomenduoti/', en: '/Referall/' },
    },
];

for (const routeOption of routesOptiosn) {
    for (const [lang, val] of Object.entries(routeOption.values)) {
        routes.add(
            routeOption.name,
            lang,
            val,
            routeOption.path,
            routeOption.options,
        );
    }
}

export default routes;

// TODO: make types form params argument
const RouteHref = (
    name: string,
    locale?: string | null,
    params?: unknown,
): string => {
    const {
        urls: { as },
    } = routes.findAndGetUrls(
        name,
        typeof locale === 'string' ? locale : undefined,
        params,
    );
    return as;
};

export { RouteHref };

export const { Router } = routes;

export const RouterLink = routes.Link;
