import axios, { AxiosError, AxiosInstance } from 'axios';
import * as Sentry from '@sentry/nextjs';
// import { dynamicSamplingContextToSentryBaggageHeader } from '@sentry/utils';
import { useSelector } from 'react-redux';
import { RootState, StoreType } from '@store/types';
import TsPromise from 'ts-promise';
import * as AxiosLogger from 'axios-logger';
import RequestError from './errors/RequestError';
import ResponseError from './errors/ResponseError';
import NetworkError from './errors/NetworkError';
import UnauthorizedError from './errors/UnauthorizedError';
import getConfig from 'next/config';
import { Router as i18nRouter } from '@root/router';

export type ValidationServerData<T = {}> = ServerData<T> & {
    validate: string;
};

export type ServerData<T = {}> = T & {
    success: boolean;
};

export type ServerResponse<T extends {}> = {
    data: ServerData<T>;
};

const createAxios = (
    token: string | null,
    locale: string | null = null,
): AxiosInstance => {
    const { publicRuntimeConfig } = getConfig() || { publicRuntimeConfig: {} };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const headers: any = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
    };
    if (locale !== null) {
        headers.locale = locale;
    }


    const instance = axios.create({
        baseURL: publicRuntimeConfig.API_URL,
        headers,
        validateStatus: (status: number) => {
            return status >= 200 && status < 400; // allow 200-399: default (200-299) and redirects (300-399)
        },
    });

    if (process.env.NODE_ENV === 'development') {
        instance.interceptors.request.use(AxiosLogger.requestLogger);
        instance.interceptors.response.use(AxiosLogger.responseLogger);
    }

    instance.interceptors.response.use(
        <T>(response: T) =>
            TsPromise.resolve<T>(response) as unknown as Promise<T>,
        (error: AxiosError) => {
            // Request made and server responded
            if (error.response) {
                if (error.response.status === 401) {
                    // Unauthorized
                    return TsPromise.reject(new UnauthorizedError(error));
                }
                // The error from response
                Sentry.setExtra('AxiosResponseError', error);
                return TsPromise.reject(new ResponseError(error));
            }
            if (error.request) {
                // The internal server error
                // The request was made but no response was received
                Sentry.setExtra('AxiosRequestError', error);
                return TsPromise.reject(new RequestError(error));
            }
            // The error with request from client side?
            Sentry.setExtra('AxiosNetworkError', error);
            return TsPromise.reject(new NetworkError(error));
        },
    );

    return instance;
};

const axiosWithStore = (store: StoreType): AxiosInstance => {
    const { token } = store.getState().auth;
    const { currentLanguage } = store.getState().i18n;
    const instance = createAxios(token, currentLanguage);
    return instance;
};

// TODO: add back link
const useAxios = (): AxiosInstance => {
    const { token, currentLanguage } = useSelector((state: RootState) => ({
        token: state.auth.token,
        currentLanguage: state.i18n.currentLanguage,
    }));
    const instance = createAxios(token, currentLanguage);
    instance.interceptors.response.use(
        <T>(response: T) =>
            TsPromise.resolve<T>(response) as unknown as Promise<T>,
        (error: AxiosError) => {
            // Request made and server responded
            if (error instanceof UnauthorizedError) {
                // Unauthorized
                return TsPromise.resolve().then(() =>
                    i18nRouter.pushRoute(
                        'landing_home',
                        {
                            showLogin: 'true',
                        },
                        currentLanguage,
                        {
                            locale: currentLanguage,
                        },
                    ),
                );
            }
            return TsPromise.reject(error);
        },
    );
    return instance;
};

export default createAxios(null, null);

export { axiosWithStore, useAxios, createAxios };
