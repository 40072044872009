import { HYDRATE } from 'next-redux-wrapper';
import { Reducer } from 'redux';
import { CoockieState, CoockieActionTypes, CoockieActionsType } from './types';

const initialState: CoockieState = {
    showAlert: true,
    referral: null,
};

const coockieReducer: Reducer<CoockieState, CoockieActionsType> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case HYDRATE:
            return {
                ...state,
                ...action.payload.coockie,
            };
        case CoockieActionTypes.SET_ALERT: {
            return {
                ...state,
                showAlert: action.payload,
            };
        }
        case CoockieActionTypes.SET_REFERRAL: {
            return {
                ...state,
                referral: action.payload,
            };
        }
        default:
            return state;
    }
};

export default coockieReducer;
