export type BaseErrorToStore = {
    name: string;
};

abstract class BaseError extends Error {
    constructor(message = '') {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
    }

    toStore(): BaseErrorToStore {
        return {
            name: this.name,
        };
    }
}
export default BaseError;
