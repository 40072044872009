import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import auth from './auth';
import coockie from './coockie';
import i18n from './i18n';
import services from './services';
import { RootState } from './types';

const rootReducer = combineReducers<RootState>({
    form,
    coockie,
    auth,
    i18n,
    services,
});

export default rootReducer;
