import BaseError from './BaseError';

export default class NotificationError extends BaseError {
    public prev?: Error | BaseError;

    constructor(message: string, prev?: Error | BaseError) {
        super(message);
        this.name = 'NotificationError';
        this.prev = prev;
    }
}
