import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { Trans } from 'react-i18next';
import styles from './Maintenance.module.scss';
import { Page } from './Type';

const Maintenance: Page = () => {
    return (
        <>
            <Helmet>
                <body className={styles.body} />
            </Helmet>
            <div className={styles.container}>
                <div className={styles.mountains}>
                    <img src="/static/img/home-top-background.png" alt="" />
                </div>

                <div
                    className={classnames('container', styles.inner_container)}
                >
                    <div className={styles.logo_container}>
                        <img src="/static/img/logos/logo.svg" alt="logo" />
                    </div>
                    <div className={styles.text_container}>
                        <h1 className={styles.title}>
                            <Trans i18nKey={`title`} ns="maintancePage">
                                Our website is almost ready!
                            </Trans>
                        </h1>
                        <h2 className={styles.description}>
                            <Trans i18nKey={`description`} ns="maintancePage">
                                We are working on new upgrade. Please come back
                                later
                            </Trans>
                        </h2>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Maintenance;
