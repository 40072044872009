import { HidrateAction, PayloadAction } from '../types';

export const COOCKIE_HIDE_ALERT_COOCKIE = 'COOCKIE_HIDE_ALERT_COOCKIE';
export const COOCKIE_REF_COOCKIE = 'COOCKIE_REF_COOCKIE';

export type CoockieState = {
    readonly showAlert: boolean;
    readonly referral: string | null;
};

export enum CoockieActionTypes {
    SET_ALERT = 'SET_ALERT',
    SET_REFERRAL = 'SET_REFERRAL',
}

export type CoockieSetAlertAction = PayloadAction<
    CoockieActionTypes.SET_ALERT,
    boolean
>;

export type CoockieSetReferralAction = PayloadAction<
    CoockieActionTypes.SET_REFERRAL,
    string | null
>;

export type CoockieActionsType =
    | HidrateAction
    | CoockieSetAlertAction
    | CoockieSetReferralAction;
