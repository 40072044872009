/* eslint-disable import/prefer-default-export */
import { setCookie } from '@utils/cookie';
import { AppThunk, createPayloadAction } from '../types';
import {
    CoockieActionTypes,
    CoockieSetAlertAction,
    CoockieSetReferralAction,
    COOCKIE_HIDE_ALERT_COOCKIE,
    COOCKIE_REF_COOCKIE,
} from './types';

const CoockieSetAlert = (value?: boolean): CoockieSetAlertAction => {
    if (typeof value === 'undefined') {
        return createPayloadAction(CoockieActionTypes.SET_ALERT, true);
    }
    return createPayloadAction(CoockieActionTypes.SET_ALERT, value);
};

const CoockieSetReferral = (
    value?: string | null,
): CoockieSetReferralAction => {
    if (typeof value === 'undefined') {
        return createPayloadAction(CoockieActionTypes.SET_REFERRAL, null);
    }
    return createPayloadAction(CoockieActionTypes.SET_REFERRAL, value);
};

export const hideAlert = (): AppThunk => (dispatch) => {
    setCookie(COOCKIE_HIDE_ALERT_COOCKIE, 'true');
    dispatch(CoockieSetAlert(false));
};

export const setReferral = (value: string): AppThunk => (dispatch) => {
    setCookie(COOCKIE_REF_COOCKIE, value);
    dispatch(CoockieSetReferral(value));
};
