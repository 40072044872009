import { HidrateAction, PayloadAction } from '../types';

export enum I18nActionTypes {
    SET_LANGUAGE = 'SET_LANGUAGE',
}
export type I18nState = {
    readonly currentLanguage: string | null;
};

export type I18nSetLanguageAction = PayloadAction<
    I18nActionTypes.SET_LANGUAGE,
    string
>;

export type I18nActionsTypes = HidrateAction | I18nSetLanguageAction;
