import { HYDRATE } from 'next-redux-wrapper';
import { Reducer } from 'redux';
import { I18nState, I18nActionTypes, I18nActionsTypes } from './types';

const initialState: I18nState = {
    currentLanguage: null,
};

const i18nRedurcer: Reducer<I18nState, I18nActionsTypes> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case HYDRATE:
            return {
                ...state,
                currentLanguage:
                    state.currentLanguage ||
                    action.payload.i18n.currentLanguage,
            };
        case I18nActionTypes.SET_LANGUAGE:
            return {
                ...state,
                currentLanguage: action.payload,
            };
        default:
            return state;
    }
};

export default i18nRedurcer;
