// pages/_app.js
import React from 'react';
import NextApp, { AppContext, AppProps } from 'next/app';
// import { NextWebVitalsMetric } from 'next/app';
import wrapper from '@store/index';
import initializeStore from '@store/initialize';
import './App.scss';
import Maintenance from './Maintenance';
import { appWithI18Next } from 'ni18n';
import ni18nConfig from '../../ni18n.config';
import getConfig from 'next/config';
import { createAxios } from '@root/utils/axios';

type StatusResponse = {
    success: boolean;
    status: string;
};

const App = ({ Component, pageProps }: AppProps) => {
    const { publicRuntimeConfig } = getConfig();
    if (
        publicRuntimeConfig.MAINTANCE_MODE === 'true' ||
        (pageProps as any).publicProjectStatus !== 'ok'
    ) {
        return <Maintenance />;
    }
    return <Component {...pageProps} />;
};

App.getInitialProps = wrapper.getInitialAppProps(
    (store) => async (appContext: AppContext) => {
        // calls page's `getInitialProps` and fills `appProps.pageProps`
        const appProps = await NextApp.getInitialProps(appContext);
        const { Component, ctx } = appContext;
        await initializeStore({ ...ctx, store });
        const publicProjectStatus = await createAxios(null, null)
            .get<StatusResponse>('/public/status')
            .then((res) => res.data.status)
            .catch(() => 'error');
        appProps.pageProps = {
            publicProjectStatus,
            ...appProps.pageProps,
            ...(Component.getInitialProps
                ? await Component.getInitialProps(ctx)
                : {}),
        };
        return { ...appProps };
    },
);

export default wrapper.withRedux(appWithI18Next(App, ni18nConfig));